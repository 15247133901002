<!--
 * @Author: wxb
 * @Date: 2021-07-15 21:24:07
 * @LastEditTime: 2021-07-15 23:55:04
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\exhibition.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='6'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/special'}">专题</el-breadcrumb-item>
        <el-breadcrumb-item>{{breadcrumbName}}</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="project">
        <div class="project-nav">
          <ul class="nav-list">
            <li class="nav-item"
                :class="$route.name==='preExhi'?'active':''"
                @click="handleClickNav('preExhi')">
              拟办展会
            </li>
            <li class="nav-item"
                :class="$route.name==='buildExhi' ? 'active' : '' "
                @click="handleClickNav('buildExhi')">
              已办展会
            </li>
          </ul>
        </div>
        <div class="project-content">
          <router-view />
        </div>
      </div>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
export default {
  name: 'exhibition',
  components: {
    headerNav,
    footerNav
  },
  data() {
    return {
      breadcrumbName: ''
    }
  },
  watch: {
    $route(to, from) {
      this.$nextTick(() => {
        this.breadcrumbName = to.meta.title
      })
    }
  },
  methods: {
    handleClickNav(name) {
      if (this.$route.name === name) {
        return
      }
      this.$router.push({
        name
      })
    }
  },
  mounted() {
    this.breadcrumbName = this.$route.meta.title
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.project {
  display: flex;
  &-nav {
    width: 23.2rem;
    height: 87.5rem;
    background: url("../../assets/bg-nav.png");

    .nav {
      &-list {
        padding-left: 1.2rem;
        padding-top: 2rem;
      }
      &-title {
        width: 20.8rem;
        height: 6rem;
        line-height: 6rem;
        font-size: 1.8rem;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.05);
        text-align: justify;
        font-weight: 700;
        background: url("../../assets/Project/label.png");
        background-repeat: no-repeat;
        background-position: 0rem 2.5rem;
        padding-left: 2rem;
      }
      &-item {
        width: 20.8rem;
        height: 6rem;
        line-height: 6rem;
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.05);
        font-size: 1.8rem;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        padding-left: 2.5rem;
        cursor: pointer;

        &.active {
          background: #f1f9ff;
          border-left: 0.3rem solid #0073bc;
          color: #0073bc;
          font-weight: 700;
        }
      }
    }
  }

  &-content {
    flex: 1;
  }
}
</style>
